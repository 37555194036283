@import "/src/styles/mixin";

.wrap {
  display: flex;
  margin-bottom: 16px;
  gap: 20px;
  align-items: flex-start;
  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
}

.text {
  @include font16Bold;
  white-space: nowrap;
}

.list {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    width: 100%;
    padding: 5px 0;
    gap: 8px;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}

.tag {
  @include font14;
  padding: 6px 12px;
  border-radius: var(--border-radius-small);
  background-color: var(--blue-background-color);
  white-space: nowrap;
}