.list {
  display: grid;
  flex-wrap: wrap;
  gap: 32px;
  grid-template-columns: repeat(auto-fit, minmax(343px, 1fr));
  margin-bottom: 80px;
  @media only screen and (min-width: 1366px) and (max-width: 1559.98px) {
    gap: 20px;
  }
  @media only screen and (min-width: 1024px) and (max-width: 1365.98px) {
    margin-bottom: 60px;
  }
  @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
    gap: 12px;
  }
  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    margin-bottom: 40px;
    justify-content: space-between;
  }
}
