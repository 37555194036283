@import "/src/styles/mixin";

.wrap {
  padding: 32px;
  display: flex;
  gap: 12px;
  background-color: var(--white-color);
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--grey-lines-color);

  @media screen and (max-width: 790px) {
    padding: 12px 6px;
    gap: 6px;
  }
}

.img {
  height: 100%;
  object-fit: contain;

  &Wrap {
    position: relative;
    width: 230px;
    height: 230px;
    padding-top: 24px;

    @media only screen and (min-width: 1024px) and (max-width: 1365.98px) {
      width: 130px;
      height: 130px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
      width: 92px;
      height: 92px;
    }

    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      width: 100px;
      height: 100px;
    }
  }
}

.label {
  @include font18;
  position: relative;
  padding: 12px 24px;
  white-space: nowrap;
  text-align: center;
  background-position: center;
  background-image: url("../../../../../assets/images/label-background.svg");
  background-repeat: no-repeat;
  background-size: contain;

  &Wrap {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}

.price {
  @include font18;
  display: flex;
  align-items: center;

  &Wrap {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
  }

  &Content {
    display: flex;
    flex-direction: column;
  }

  &Old {
    @include font14;
    text-decoration: line-through;
  }

  &Discount {
    @include font12;
    padding: 2px 4px;
    background-color: var(--primary-color);
    border-radius: 4px;
  }
}

.name {
  @include font14;
  margin-bottom: 32px;
  height: 50px;
  display: -webkit-box;
  color: var(--secondary-color);
  overflow: hidden;

  @media screen and (max-width: 425px) {
    margin-bottom: 16px;
  }
}

.button {
  &Wrap {
    display: flex;
    align-items: center;
    gap: 12px;

    @media screen and (max-width: 320px) {
      gap: 6px;
    }
  }
}

.favourite {
  display: flex;

  &Icon {
    transition: fill 0.2s ease;

    &Active {
      fill: #9ea0a3;
    }
  }

  &:hover {
    .favouriteIcon {
      fill: #9ea0a3;
    }
  }
}

.addToCart,
.inCart,
.isNotAvailable {
  padding: 10px 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: var(--border-radius-small);
  transition: background 0.2s ease, border-color 0.2s ease;

  &:hover {
    border-color: var(--hover-primary-color);
    background-color: var(--hover-primary-color);
  }
}

.addToCart {
  white-space: nowrap;
  background-color: var(--primary-color);
}

.inCart {
  white-space: nowrap;
  border-color: var(--secondary-color);
  background-color: transparent;
}

.isNotAvailable {
  white-space: nowrap;
  background-color: var(--blue-background-color);

  &:hover {
    cursor: default;
    border-color: transparent;
    background-color: var(--blue-background-color);
  }
}
