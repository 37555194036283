@import "/src/styles/mixin";

.wrap {
  display: flex;
  flex-direction: column;
  gap: 26px;
  max-width: 440px;
  padding: 24px;
  border: 1px solid var(--grey-light-color);
  border-radius: var(--border-radius-medium);
  transition: all .25s ease;

  .head {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    gap: 12px;

    .link {
      width: 100%;
      max-width: 168px;

      .img {
        width: 100%;
        height: auto;
        object-fit: contain;
        aspect-ratio: 1 / 1;
      }
    }

    .actions {
      width: 100%;
      max-width: 200px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .edit_actions {
        display: flex;
        flex-direction: row;
        gap: 8px;

        .button {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px;
          background-color: var(--blue-background-color);
          border-radius: var(--border-radius-small);
          transition: .25s ease;

          &:hover {
            background-color: var(--grey-light-color);
            transform: translateY(-2px);

            .icon {
              fill: var(--white-color);

              path {
                fill: var(--white-color);
              }
            }
          }
        }
      }

      .repost {
        @include contentButton;
        padding: 12px 12px;
        transition: all .25s ease;
      }

      .remove {
        @include contentButton;
        padding: 12px 12px;
        background-color: var(--blue-background-color);
        border: 1px solid var(--blue-background-color);
        transition: all .25s ease;

        &:hover {
          background-color: var(--blue-background-color);
          border-color: var(--blue-background-color);
        }
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;

    .status {
      @include font14;
      width: fit-content;

      &_text {
        margin-left: 6px;
      }
    }

    .name {
      @include font20;
    }

    .price {
      @include font18;
    }

    .range {
      margin: 13px 0 10px;
      width: 100%;
      height: 5px;
      background-color: var(--grey-lines-color);

      .line {
        height: 5px;
        background-color: var(--primary-color);
      }
    }

    .posting {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      color: var(--grey-darker-color);

    }
  }

  &:hover {
    border-color: var(--white-color);
    box-shadow: 0px 0px 6px 0px #00000020;
  }

  @media screen and (max-width: 540px) {
    max-width: 100%;
    border-radius: 0;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid var(--grey-lines-color);
  }

  @media screen and (max-width: 375px) {
    padding: 12px 0;
  }
}


















































// .item {
//   padding: 12px 0;
//   display: grid;
//   grid-template-columns: 200px 450px 1fr;
//   grid-template-rows: repeat(2, 100px);
//   grid-column-gap: 60px;
//   border-bottom: 1px solid var(--grey-lines-color);

//   @media only screen and (min-width: 1440px) and (max-width: 1559.98px) {
//     grid-template-columns: 200px 440px 1fr;
//   }

//   @media only screen and (min-width: 1366px) and (max-width: 1439.98px) {
//     grid-template-columns: 200px 350px 1fr;
//   }

//   @media only screen and (min-width: 768px) and (max-width: 1365.98px) {
//     grid-template-columns: 135px 1fr;
//     grid-template-rows: 135px 60px;
//     grid-column-gap: 40px;
//     grid-row-gap: 16px;
//   }

//   @media only screen and (min-width: 320px) and (max-width: 767.98px) {
//     grid-template-columns: 140px 1fr;
//     grid-template-rows: 140px 1fr 1fr;
//     grid-column-gap: 8px;
//     grid-row-gap: 8px;
//   }
// }

// .link {
//   grid-area: 1 / 1 / 3 / 2;

//   @media only screen and (min-width: 768px) and (max-width: 1365.98px) {
//     grid-area: 1 / 1 / 2 / 2;
//   }

//   @media only screen and (min-width: 320px) and (max-width: 767.98px) {
//     grid-area: 1 / 1 / 3 / 2;
//   }
// }

// .img {
//   height: 100%;
//   object-fit: cover;
// }

// .info {
//   grid-area: 1 / 2 / 2 / 3;

//   @media only screen and (min-width: 768px) and (max-width: 1365.98px) {
//     grid-area: 1 / 2 / 2 / 3;
//   }

//   @media only screen and (min-width: 320px) and (max-width: 767.98px) {
//     grid-area: 1 / 2 / 2 / 3;
//   }
// }

// .name {
//   @include font20;

//   @media only screen and (min-width: 320px) and (max-width: 767.98px) {
//     display: -webkit-box;
//     -webkit-box-orient: vertical;
//     -webkit-line-clamp: 2;
//     line-clamp: 2;
//     overflow: hidden;
//   }
// }

// .price {
//   @include font18;
//   margin-bottom: 12px;

//   @media only screen and (min-width: 320px) and (max-width: 767.98px) {
//     margin-bottom: 0;
//   }
// }

// .line {
//   position: absolute;
//   left: 0;
//   height: 100%;
//   background-color: var(--primary-color);
//   border-radius: 2px;
//   width: 80%;
//   max-width: 100%;

//   &Wrap {
//     position: relative;
//     width: 100%;
//     margin-bottom: 10px;
//     height: 5px;
//     border-radius: 5px;
//     background-color: var(--grey-lines-color);
//   }
// }

// .posting {
//   &Text {
//     @include font14;
//     display: flex;
//     justify-content: space-between;
//     color: var(--grey-color);

//     &Span {
//       position: relative;
//       color: var(--black-color);
//       cursor: pointer;

//       &::before {
//         content: "";
//         position: absolute;
//         bottom: 0;
//         left: 0;
//         width: 100%;
//         height: 1px;
//         border-bottom: 1px dashed var(--black-color);
//       }
//     }
//   }
// }

// .action {
//   @media only screen and (min-width: 320px) and (max-width: 767.98px) {
//     width: 100%;
//     padding-left: 0;
//     padding: 8px 0;
//     font-size: 0;
//     line-height: unset;
//     background-position: center;
//     background-color: var(--blue-background-color);
//     border-radius: var(--border-radius-small);
//   }

//   &Wrap {
//     display: flex;
//     align-self: flex-end;
//     gap: 12px;
//     grid-area: 2 / 2 / 3 / 3;

//     @media only screen and (min-width: 768px) and (max-width: 1365.98px) {
//       grid-area: 2 / 1 / 3 / 2;
//       flex-direction: column;
//       align-items: flex-start;
//     }

//     @media only screen and (min-width: 320px) and (max-width: 767.98px) {
//       flex-direction: row;
//       grid-area: 2 / 2 / 3 / 3;
//     }
//   }
// }

// .button {
//   &Wrap {
//     grid-area: 1 / 3 / 3 / 4;
//     display: flex;
//     flex-direction: column;
//     gap: 12px;

//     @media only screen and (min-width: 768px) and (max-width: 1365.98px) {
//       grid-area: 2 / 2 / 3 / 3;
//       flex-direction: row;
//     }

//     @media only screen and (min-width: 320px) and (max-width: 767.98px) {
//       grid-area: 3 / 1 / 4 / 3;
//     }
//   }
// }

// .text {
//   @include font14;
//   align-self: flex-end;
// }

// .cancelledText {
//   @include font14;
//   align-self: flex-end;
//   color: var(--red-color);
// }