@import "/src/styles/mixin";

.button {
  @include font14;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  margin-bottom: 32px;
  border-radius: 45px;
  border: 2px solid var(--primary-color);
  background-color: transparent;

  span {
    font-size: 24px;
  }

  &:hover {
    border-width: 2px;
  }
}

.list {
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 1fr));
  gap: 24px;

  @media screen and (max-width: 1240px) {
    grid-template-columns: repeat(2, minmax(auto, 1fr));
  }

  @media screen and (max-width: 540px) {
    grid-template-columns: repeat(1, minmax(auto, 1fr));
    gap: 0;
  }
}