.action {
  &Wrap {
    position: absolute;
    top: 16px;
    right: 12px;
    display: flex;
    align-items: center;
    z-index: 1;
    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      top: 0;
      right: 0;
    }
  }
}
.favourite {
  &Icon {
    transition: fill 0.2s ease;
    &Active {
      fill: #9ea0a3;
    }
  }
  @media only screen and (min-width: 768px) {
    &:hover {
      .favouriteIcon {
        fill: #9ea0a3;
      }
    }
  }
}
