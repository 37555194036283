.Toastify__toast-container--bottom-right {
  width: 400px;
  bottom: 20px;
  right: 76px;
  padding: 0;

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    width: 288px;
    left: 16px;
    right: unset;
  }
}

.Toastify__toast {
  // padding: 40px 30px;
  padding: 16px 12px;
  margin-bottom: 0;
  border-radius: 10px;

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    padding: 20px 14px;
  }

  &-body {
    // flex-start
    align-items: center;
  }

  &-icon {
    // margin-top: 6px;
    margin-inline-end: 12px;
  }
}