@import "src/styles/mixin";

.reviews_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;

  .filters {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 12px;

    .filter {
      @include font14;
      padding: 6px 15px;
      background-color: var(--blue-background-color);
      border-radius: 100px;
      transition: all .25s;

      &:hover {
        cursor: pointer;
        background-color: var(--grey-lines-color);
      }
    }

    .active {
      @include font14;
      padding: 6px 15px;
      background-color: var(--primary-color);
      border-radius: 100px;
      transition: all .25s;

      &:hover {
        cursor: pointer;
        background-color: var(--hover-primary-color);
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .no_data {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .subtitle {
      margin-top: 15%;
    }
  }
}