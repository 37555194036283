@import "/src/styles/mixin";

.city {
  margin-bottom: 24px;
  padding: 12px 24px;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--border-radius-normal);
  background-color: var(--grey-lines-color);

  &Text {
    @include font14;
  }

  &Action {
    display: flex;
    gap: 8px;

    &Text {
      @include font14;
      color: var(--grey-color);
    }

    &Button {
      @include font14;
      @include dashedBottomLine;
      max-width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    margin-bottom: 12px;
    padding: 12px 8px;
    flex-direction: column;
    align-items: flex-start;
  }
}
