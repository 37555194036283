.label {
  &Wrap {
    position: absolute;
    top: 12px;
    left: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    z-index: 2;
    user-select: none;
    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      top: 0;
      left: 0;
    }
  }
}
