@import "/src/styles/mixin";

.choices {
  &Wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-right: auto;
    @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
      display: none;
    }
  }
  &Item {
    position: relative;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 20px;
    border-radius: var(--border-radius-small);
    border: 1px solid var(--grey-lines-color);
  }
  &Text {
    @include font14;
  }
  &Close {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &Icon {
    width: 10px;
    height: 10px;
  }
}
