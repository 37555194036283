@import "/src/styles/mixin";

.storesAll {
  @include font14;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--grey-color);
  &Icon {
    transform: rotate(180deg);
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.nested {
  &Title {
    @include font18;
  }
  &Subtitle{
    @include font14;
    font-weight: 600;
    width: 100%;
    text-align: left;
    padding: 12px 28px;
    background-color: var(--grey-secondary);
    border-radius: var(--border-radius-small);
  }
  &Wrap {
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
      padding: 24px 12px;
    }
  }
  &Text {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.choosedCity {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &Text {
    @include font14;
  }
  &Geo {
    margin-right: 0;
  }
}

.delivery {
  &Title {
    @include font20;
    margin-bottom: 8px;
  }
  &Price {
    @include font12;
    padding: 0 4px;
    background-color: var(--primary-color);
  }
  &Wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &Text {
    @include font12;
    font-weight: 500;
  }
  &Company {
    &Wrap {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    &Img {
      width: 100px;
      height: 100%;
      &Wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px;
        &:not(:last-child) {
          border-bottom: 1px dashed var(--grey-lines-color);
        }
      }
    }
    &Text {
      @include font14;
      padding: 10px;
      border-radius: var(--border-radius-small);
      background-color: var(--grey-lines-color);
    }
  }
}
