@import "src/styles/mixin";

.container {
  position: relative;
  background-color: #000000;
  width: 100%;
  color: var(--white-color);
  overflow-y: hidden;
  padding: 0 0 200px 0;

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    .content {
      display: flex;
      margin-top: 75px;
      gap: 30px;
      padding-bottom: 100px;

      @media screen and (max-width: 1024px) {
        margin-top: 40px;
        flex-direction: column;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 90px;
  }
}