@import "/src/styles/mixin";

.wrap {
  max-width: 235px;
  position: relative;
  padding: 12px;
  border-radius: var(--border-radius-small);
  background-color: var(--white-color);
  border: 1px solid var(--grey-lines-color);
  transition: box-shadow 0.2s ease, border-color 0.2s ease;

  &:hover {
    border-color: transparent;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  }

  @media screen and (min-width: 320px) and (max-width: 767.98px) {
    padding: 0;
    border: none;
    &:hover {
      box-shadow: none;
    }
  }
}

.img {
  height: 100%;
  object-fit: contain;
  aspect-ratio: 1/1;
  mix-blend-mode: multiply;
  user-select: none;

  &Wrap {
    position: relative;
    display: flex;
    margin-bottom: 12px;
    padding: 10px;
    border-radius: var(--border-radius-small);

    &.notAvailable {
      opacity: 0.5;
    }
  }
}

.link {
  display: flex;
  flex-direction: column;
}

.name {
  @include font14;
  height: 50px;
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--secondary-color);
  overflow: hidden;
}

.reviews {
  margin-bottom: 8px;
}

.bottom {
  // width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  min-height: 56px;

  @media screen and (max-width: 375px) {
    // min-height: 32px;
    // flex-direction: column;
    justify-content: space-between;
    button {
      min-width: 42px;
      width: 100%;
      max-width: 42px;
    }
  }
}

.comission {
  flex-direction: column;
}

.comissionImg {
  height: 100% !important;
  object-fit: contain !important;
  aspect-ratio: 1/1 !important;
}

.showButton {
  @include contentButton;
  padding: 12px 18px;
}