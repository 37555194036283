@import "/src/styles/mixin";

.banner {
  padding: 56px 0 44px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/comission-banner.webp");
  border-radius: var(--border-radius-medium);
  @media only screen and (min-width: 768px) and (max-width: 1439.98px) {
    padding: 56px 0 28px;
  }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    padding: 48px 0 24px;
  }
  &Text {
    @include font30;
    margin-bottom: 48px;
    text-align: center;
    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      margin-bottom: 36px;
    }
  }
  &Button {
    @include font14;
    padding: 16px 44px;
  }
}
