@import 'src/styles/mixin';

.review {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px 0;

  .product {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    position: relative;

    @media screen and (max-width: 600px) {
      padding-top: 34px;
    }

    .link {
      display: flex;
      align-items: center;
      aspect-ratio: 1/1;
    }

    &_review {
      display: flex;
      flex-direction: row;
      column-gap: 40px;

      @media screen and (max-width: 767px) {
        column-gap: 16px;
      }
    }

    &_image {
      width: 116px;
      height: 116px;
      aspect-ratio: 1/1;
      object-fit: contain;

      @media screen and (max-width: 767px) {
        width: 100px;
        height: 100px;
      }

      @media screen and (max-width: 425px) {
        width: 65px;
        height: 65px;
      }
    }

    &_info {
      display: flex;
      flex-direction: column;

      .stars {
        display: flex;
        align-items: center;
        width: fit-content;
        flex-direction: row;
        row-gap: 3px;
        margin-bottom: 0;
        border: 1px dashed transparent;
        border-radius: var(--border-radius-small);

        &_edit {
          display: flex;
          padding: 6px 6px 3px;
          align-items: center;
          width: fit-content;
          flex-direction: row;
          row-gap: 3px;
          margin-bottom: 0;
          border: 1px dashed var(--grey-lines-color);
          border-radius: var(--border-radius-small);

          .star_wrap {
            height: 18px;
            width: 17px;

            .star {

              path {
                &:hover {
                  fill: var(--primary-color);
                }
              }
            }
          }
        }

        .star_wrap {
          height: 18px;
          width: 17px;

          .star {

            path {
              &:hover {
                fill: var(--primary-color);
              }
            }
          }
        }
      }

      .price {
        display: flex;
        align-items: center;
        flex-direction: row;
        column-gap: 14px;

        .price_current {
          @include font18;
        }

        .price_old {
          @include font14;
          font-weight: 500;
          text-decoration: line-through;
        }

        .only_old {
          @include font18;
        }
      }
    }

    &_name {
      @include font14;
      font-weight: 600;
      max-width: 286px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .status {
      @include font14;
      font-weight: 700;
      padding: 2px 8px;
      text-transform: lowercase;
      color: var(--white-color);
      border-radius: var(--border-radius-small);

      @media screen and (max-width: 600px) {
        position: absolute;
        top: 0px;
        left: 0px;
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      row-gap: 12px;

      .action {
        display: flex;
        align-items: center;
        column-gap: 8px;

        span {
          @media screen and (max-width: 600px) {
            display: none;
          }
        }
      }

      @media screen and (max-width: 600px) {
        position: absolute;
        top: 0;
        right: 0;
        flex-direction: row;
        column-gap: 12px;
      }
    }
  }

  .text {
    display: inherit;
    flex-direction: inherit;
    row-gap: 16px;

    .review_text {
      @include font14;
      font-weight: 700;

      span {
        @include font14;
        font-weight: 600;
      }
    }

    .textarea {
      @include font14;
      min-height: 32px;
      padding: 4px 8px;
      max-height: 180px;
      border: 1px solid var(--grey-lines-color);
      border-radius: var(--border-radius-small);
      resize: vertical;
      outline: none;
      transition: border .25s ease;

      &:focus {
        border: 1px solid var(--secondary-color);
      }
    }

    .confirm {
      @include contentButton;
      @include font14;
      padding: 8px 12px;
      display: flex;
      font-weight: 700;
      cursor: pointer;
      margin-right: auto;
    }
  }

  .image_list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    .image_wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 126px;
      height: 126px;
      overflow: hidden;
      border-radius: var(--border-radius-small);

      .image {
        width: 126px;
        height: auto;
        cursor: zoom-in;
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding: 12px 0px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--grey-lines-color);
  }
}