.stories_wrap {
  position: relative;

  .skeleton {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
}