@import "/src/styles/mixin";

.aside {
  position: sticky;
  display: flex;
  flex-direction: column;
  top: 140px;
  max-width: 520px;
  width: 100%;
  row-gap: 24px;

  @media screen and (max-width: 1365px) {
    margin-bottom: 0;
    flex-direction: column-reverse;
  }

  &Content {
    padding: 24px 32px;
    border-radius: var(--border-radius-medium);
    background-color: var(--blue-background-color);

    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      padding: 12px 16px;
    }

    .empty_cart {
      padding: 16px 20px;
      margin-bottom: 20px;
      border-radius: var(--border-radius-small);
      border: 1px solid var(--primary-color);
      text-align: center;

      .empty_cart_title {
        font-weight: 700;
        margin-bottom: 16px;
      }

      .empty_cart_text {
        @include font14;
        // color: var();
      }
    }
  }

  &Wrap {
    padding: 10px 0;

    &Title {
      @include font18;
    }

    &:not(:first-child) {
      border-top: 1px solid var(--grey-lines-color);
    }

    &:nth-child(3) {
      margin-bottom: 20px;
    }
  }

  @media only screen and (max-width: 1559.98px) {
    max-width: 387px;
  }

  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
}

.text {
  @include font14;

  &Transfer {
    @include font14Bold;
    background-color: var(--primary-color);
    padding: 0 8px;
  }

  &Sale {
    @include font14Bold;
    color: var(--red-color);
  }

  &Bold {
    @include font14Bold;
  }

  &Content {
    height: 28px;
    overflow: hidden;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &Wrap {
      display: flex;
      justify-content: space-between;
    }
  }
}

.button {
  @include font14;
  padding: 16px 26px;
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.subtext {
  font-size: 11px;
  line-height: 20px;
  color: var(--grey-color);
}

.link {
  font-size: 11px;
  line-height: 20px;
  color: var(--grey-color);
  border-bottom: 1px solid var(--grey-color);
}
