@import "/src/styles/mixin";

.categories {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-bottom: 32px;
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    margin-bottom: 24px;
    gap: 12px;
  }
  &Item {
    @include font14Bold;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 17.5%;
    max-width: 209px;
    text-align: center;
    @media only screen and (min-width: 1440px) and (max-width: 1559.98px) {
      width: 17%;
    }
    @media only screen and (min-width: 1024px) and (max-width: 1439.98px) {
      width: 16.6%;
    }
    @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
      width: 21%;
      &:nth-child(n + 10) {
        display: none;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      width: 30%;
      font-size: 9px;
      line-height: 13px;
    }
    @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
      max-width: unset;
    }
  }
}
