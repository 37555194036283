@import "/src/styles/mixin";

.item {
  &:not(:last-child) {
    margin-bottom: 50px;
  }

  .head {
    display: flex;
    background-color: var(--primary-color);
    font-weight: 700;
    padding: 8px 16px;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--border-radius-normal);

    &_info {
      display: flex;
      flex-direction: column;
      gap: 2px;

      .title {
        @include font16Bold;
        line-height: 32px;
      }

      .date {
        @include font14;
        font-weight: 600;
      }
    }
  }

  .cancel {
    background-color: var(--blue-background-color);
  }

  @media only screen and (max-width: 767.98px) {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .show_all {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    @include font14Bold;
    padding: 4px 12px 4px 12px;
    background-color: var(--blue-background-color);
    border-radius: var(--border-radius-small);
    margin-left: auto;
    margin-top: 24px;
    width: fit-content;

    .arrow {
      transition: all .25s ease;
    }

    .drop {
      transform: rotate(90deg) translate(2px, 0);
    }
  }
}

.panel {
  padding: 24px 0px;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid var(--grey-lines-color);
  }

  &Status {
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 767.98px) {
      width: 100%;
      order: 3;
    }
  }

  &Price {
    @include font18;

    @media only screen and (max-width: 767.98px) {
      margin-left: auto;
      order: 2;
    }
  }

  &Text {
    @include font14;
  }

  @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
    padding: 24px 30px;
  }

  @media only screen and (max-width: 767.98px) {
    padding: 15px 20px 20px;
    align-items: flex-start;
  }
}

.img {
  width: 116px;
  height: 116px;
  object-fit: contain;
  mix-blend-mode: multiply;

  @media only screen and (max-width: 767.98px) {
    width: 90px;
    height: 90px;
  }

  &Wrap {
    display: flex;
    margin-right: 50px;
    background-color: var(--blue-background-color);

    @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
      margin-right: 30px;
    }

    @media only screen and (max-width: 767.98px) {
      margin-right: 24px;
      order: 1;
    }
  }

}