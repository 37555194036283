@import "/src/styles/mixin";

.title {
  @include font24;
  margin-bottom: 24px;
}
.link {
  max-width: 160px;
  display: flex;
  align-items: center;
  padding: 20px;
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    height: 90px;
  }
}
.img {
  height: 100px;
  object-fit: contain;
}
