@import "/src/styles/mixin";

.banner {
  width: 100%;
  height: 100%;
  object-fit: contain;
  &Wrap {
    margin-bottom: 20px;
  }
}

.title {
  @include font24;
  margin-bottom: 20px;
}

.blank {
  max-width: 960px;
  margin: 0 auto;
  &Text {
    @include font14;
    margin-bottom: 40px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.button {
  @include font14;
  padding: 16px 26px;
  align-self: flex-start;
}
