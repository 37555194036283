@import "/src/styles/mixin";

.wrap {
  width: 100%;

  .button {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 24px auto 0px;

    .arrow {
      transform: rotate(-90deg);
      width: 8px;
      margin-top: 2px;

      &_open {
        transform: rotate(90deg);
        width: 8px;
        margin-top: 2px;
      }
    }

  }

  .description {
    position: relative;
    margin-bottom: 24px;
    height: 52px;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(180deg, rgba(255, 255, 255, .1) 0%, #FFFFFF 100%);
      z-index: 1;
    }

    h2 {
      @include font24;
      margin-bottom: 24px;
    }

    a {
      @include lineHover;
    }

    p {
      @include font14;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    ul {
      list-style-type: circle;
      padding-left: 20px;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    &_open {
      height: auto;

      h2 {
        @include font24;
        margin-bottom: 24px;
      }

      a {
        @include lineHover;
      }

      p {
        @include font14;

        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }

      ul {
        list-style-type: circle;
        padding-left: 20px;

        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }
  }
}