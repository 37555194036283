@import "/src/styles/mixin";

.promocode {
  @include contentInput;
  width: 100%;
  padding: 16px 80px 16px 16px;
  &Wrap {
    position: relative;
  }
  &Button {
    @include font14;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translate(0, -50%);
    color: var(--secondary-color);
    transition: color 0.2s ease;
  }
}
