@import "src/styles/mixin";

.delivery {
  display: flex;
  flex-direction: column;
  margin: 16px 0px 0px;
  gap: 24px;

  &_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .delivery_name {
      @include font14;

      .name {
        @include font14Bold;
      }
    }
  }

  .delivery_info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .address {
      display: flex;
      align-items: flex-start;
      max-width: 286px;
      gap: 8px;

      .icon {
        min-width: 20px;
        height: auto;
      }
    }

    @media screen and (max-width: 630px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .delivery_items {
    display: flex;
    flex-direction: row;
    gap: 12px;
    height: auto;
    overflow: hidden;

    .panel {
      padding: 24px 0px;
      display: flex;

      &:not(:last-child) {
        border-bottom: 1px solid var(--grey-lines-color);
      }

      .img {
        width: 116px;
        height: 116px;
        object-fit: contain;
        mix-blend-mode: multiply;

        @media only screen and (min-width: 320px) and (max-width: 767.98px) {
          width: 65px;
          height: 65px;
          border-radius: 10px;
        }

        &Wrap {
          display: flex;
          margin-right: 50px;
          background-color: var(--blue-background-color);

          @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
            margin-right: 30px;
          }

          @media screen and (max-width: 630px) {
            margin-right: 8px;
          }
        }
      }

      &Status {
        display: flex;
        width: 100%;
        justify-content: space-between;
        white-space: nowrap;
        gap: 12px;

        @media screen and (max-width: 630px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      &Info {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      &Price {
        @include font18;
      }

      &Buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;
        margin-left: auto;
      }

      &Name {
        @include font14;
        display: -webkit-box;
        width: 100%;
        max-width: 286px;
        white-space: wrap;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &Text {
        @include font14;
        white-space: nowrap;
      }

      @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
        padding: 24px 30px;
      }

      @media only screen and (min-width: 320px) and (max-width: 767.98px) {
        padding: 15px 0px 20px;
        align-items: flex-start;
      }
    }

    .panel_wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      max-width: 110px;

      .imgWrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 110px;
        height: 110px;
        padding: 8px;
        background-color: var(--blue-background-color);

        .img {
          max-height: 93px;
          object-fit: contain;
          mix-blend-mode: multiply;

          @media screen and (max-width: 500px) {
            max-height: 60px;
          }
        }

        @media screen and (max-width: 500px) {
          height: 66px;
          width: 66px;
          padding: 16px;
        }
      }
    }

    .counter {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 110px;
      height: 110px;
      padding: 8px;
      background-color: var(--blue-background-color);
      cursor: pointer;

      @media screen and (max-width: 500px) {
        height: 66px;
        width: 66px;
        padding: 16px;
      }
    }
  }

  .expanded {
    flex-direction: column;
    gap: 8px;
  }

  .active {
    height: auto;
    flex-direction: row;
  }

  .show_all {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    @include font14Bold;
    padding: 4px 12px 4px 12px;
    background-color: var(--blue-background-color);
    border-radius: var(--border-radius-small);
    margin-left: auto;

    .arrow {
      transition: all .25s ease;
    }

    .drop {
      transform: rotate(90deg) translate(2px, 0);
    }
  }
}