.container {
  display: flex;
  gap: 60px;
  @media only screen and (max-width: 1365px) {
    flex-direction: column;
    gap: 24px;
  }
}

.cards {
  width: 100%;
}
