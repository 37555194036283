@import "/src/styles/mixin";

.status {
  position: relative;

  &_wrap {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: 8px;
    cursor: pointer;

    .current_status {
      @include font14Bold;
    }

    .info {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      padding: 1px 6px;
      width: 12px;
      font-size: 10px;
      line-height: 10px;
      color: var(--white-color);
      background-color: var(--secondary-color);
    }
  }

  .dropdown {
    padding: 12px 32px;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 100%;
    right: 0;
    width: auto;
    min-width: 190px;
    height: 0;
    opacity: 0;
    border-radius: 16px;
    background-color: var(--white-color);
    box-shadow: 0px 2px 4px 0px #00000040;
    transition: all .25s ease;
    overflow: hidden;
    cursor: default;
    user-select: none;

    .item {
      position: relative;
      @include font14Bold;
      opacity: 0;
      transition: .25s;

      &:last-child {
        &::before {
          display: none;
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: -20px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        transform: translate(0, -40%);
        background-color: var(--white-color);
        border: 2px solid var(--grey-lines-color);
        z-index: 11;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -17px;
        transform: translate(0, 25%);
        background-color: var(--grey-lines-color);
        height: 40px;
        width: 2px;
        z-index: 11;
      }

      &_active {
        &::after {
          background-color: var(--white-color);
          border: 2px solid var(--primary-color);
        }

        &::before {
          background-color: var(--primary-color);
        }
      }
    }
  }
}

.status:hover {
  .dropdown {
    height: 245px;
    opacity: 1;
    z-index: 10;

    .item {
      opacity: 1;
    }
  }
}