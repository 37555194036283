@import "/src/styles/mixin";

.wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 64px;
  max-width: 802px;
  margin: 20px 0 64px;

  .form_wrap {
    width: 100%;

    .title {
      @include font18;
      margin-bottom: 12px;
    }

    .text {
      @include font14;
      margin-bottom: 36px;
    }

    .form {
      display: flex;
      flex-direction: column;

      .textarea {
        border: none;
        width: 100%;
        max-width: 802px;
        min-width: 100%;
        min-height: 142px;
        height: auto;
        max-height: 300px;
        padding: 12px 16px;
        margin-bottom: 16px;
        border: 1px solid var(--grey-lines-color);
        border-radius: var(--border-radius-small);
        resize: none;
      }

      .textarea:focus {
        outline: none;
      }

      .label {
        @include font14;
        margin-bottom: 12px;
        color: var(--grey-third);
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    row-gap: 32px;

    .show_more {
      width: fit-content;
      padding: 14px 57px;
      border: 1px solid var(--black-color);
      cursor: pointer;
      border-radius: var(--border-radius-small);
      transition: 0.15s ease;
    }

    .show_more:hover {
      background-color: var(--grey-secondary);
    }
  }
}

.submit_button {
  @include contentButtonTransparent;
  width: fit-content;
  padding: 14px 50px;
}
