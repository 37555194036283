@import "/src/styles/mixin";

.buttonWrap {
  display: flex;
  width: auto;
  min-width: 48px;

  @media screen and (max-width: 320px) {
    min-width: 42px;
  }
}

.addToCart,
.inCart,
.isNotAvailable {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border: 1px solid transparent;
  border-radius: var(--border-radius-small);
  transition: background 0.2s ease, border-color 0.2s ease;

  &:hover {
    border-color: var(--hover-primary-color);
    background-color: var(--hover-primary-color);
  }

}

.addToCart {
  background-color: var(--primary-color);
}

.inCart {
  border-color: var(--secondary-color);
  background-color: transparent;
}

.isNotAvailable {
  background-color: var(--blue-background-color);

  &:hover {
    cursor: default;
    border-color: transparent;
    background-color: var(--blue-background-color);
  }
}

.showButton {
  @include font14;
  @include contentButton;
  display: block;
  padding: 10px 18px;
  text-align: center;
}