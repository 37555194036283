@import "/src/styles/mixin";

.select {
  position: relative;
  max-width: 300px;
  width: auto;
  z-index: 2;

  &Wrap {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-left: auto;

    @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
      font-weight: 800;
      margin-left: 0;
    }

    div {
      div {
        @media screen and (max-width: 767px) {
          padding-left: 0 !important;
        }
      }
    }
  }

  &Text {
    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      display: none;
    }
  }
}

.filterButton {
  display: none;

  @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
    @include font14Bold;
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 16px;

  @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
    justify-content: space-between;
    gap: 30px;
  }

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    margin-bottom: 18px;
    flex-direction: row-reverse;
  }
}

.choices {
  &Wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-right: auto;

    @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
      display: none;
    }
  }

  &Item {
    position: relative;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 20px;
    border-radius: var(--border-radius-small);
    border: 1px solid var(--grey-lines-color);
  }

  &Text {
    @include font14;
  }

  &Close {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &Icon {
    margin-left: 4px;
    width: 10px;
    height: 10px;
  }
}
